<template>
  <div class="register">
    <div class="wrapper-zone-guidance">
      <a-button type="custom-one" block @click="zonaguide"
        >Zona Informasi</a-button
      >
      <a-button type="custom-one" block @click="ScanQR">Scan QR</a-button>

      <a-button type="custom-one" block @click="kuisioner">Kuisioner</a-button>
    </div>
    <a-button type="primary" block @click="Logout">
      <span style="logout"> <a-icon type="play-circle" /> Keluar </span>
    </a-button>

    <div class="imagesregist">
      <img :src="require('@/assets/img/image.png')" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    Logout() {
      this.$store.dispatch("Logout");
    },
    handleSubmit(e) {
      e.preventDefault();
      this.$router.push({ name: "login" });
    },

    ScanQR() {
      this.$router.push({ name: "HomeReaderQR" });
    },

    kuisioner() {
      window.open(
        "https://docs.google.com/forms/d/e/1FAIpQLSeT88lkHsjf-K7LE8IaUr6PXKIlLkwYc9WbEAzUD59g6Bprbw/viewform",
        "_blank"
      );
    },

    zonaguide() {
      this.$router.push({ name: "ZoneGuide" });
    },
  },
};
</script>
